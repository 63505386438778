window.processStory = async function processSmooji(event) {
  event.preventDefault();

  const themeSelect = document.getElementById("themeSelect");

  const theme = themeSelect.options[themeSelect.selectedIndex].value;
  const hero = document.getElementById("hero").value;
  const email = document.getElementById("email").value;


  if (theme === "" || hero === "" || email === "" ) {
    const toast = document.getElementById('toast');
        toast.textContent = "Please complete the form"
        toast.classList.add('visible');
            setTimeout(() => {
            toast.classList.remove('visible');
        }, 2000);

  } else {
    console.log("Selected theme: " + theme) 
    console.log("Selected hero: " + hero) 
    console.log("Selected email: " + email) 

    var submitButton = document.getElementById("submitButton");
    submitButton.value = "Preparing data...";


    const baseUrl = 'https://api.retool.com/v1/workflows/a3858f5e-5c33-4bd8-9dde-ef33c416f32f/startTrigger';

    const requestData = {
        email: email,
        theme: theme,
        hero: hero
    };

    const requestOptions = {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestData) 
    };

    let message = ''

    await fetch(baseUrl, requestOptions)
    
    .then(response => response.json())
    .then(data => {
        const toast = document.getElementById('toast');
        toast.textContent = "Thanks! Sending story... Check your inbox"
        toast.classList.add('visible');
            setTimeout(() => {
            toast.classList.remove('visible');
        }, 5000);
    })
    .catch(error => {
        console.error('Error:', error);
        const toast = document.getElementById('toast');
        toast.textContent = "Server error. Please try again"
        toast.classList.add('visible');
            setTimeout(() => {
            toast.classList.remove('visible');
        }, 5000);
    });



  } 

}
